.page-grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "sidebar header" "sidebar content";
  min-height: 100%;
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
}

.main {
  grid-area: content;
  min-width: 0;
}
