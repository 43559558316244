@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

body {
  color: #333;
}

a {
  @apply text-blue-900;
}

/* ---common styles--- */
.main {
  @apply w-full max-w-6xl mx-auto my-4 px-4;
}

.form {
  @apply flex flex-nowrap gap-x-4;
}

.basic-info {
  @apply sticky top-4 shrink-0 self-start w-[333px];
}

.dashed-section {
  @apply p-4 border-dashed border-2;
}

.highlighted-error {
  @apply w-max px-2 bg-red-100;
}

.form-tab-table {
  @apply mt-10 overflow-x-auto;
}

.word-shortening {
  display: inline-block;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ---for badges--- */
.red {
  @apply border-red-500 bg-red-100;
}

.green {
  @apply border-green-500 bg-green-100;
}

.blue {
  @apply border-blue-500 bg-blue-100;
}

/* ---arrows--- */
.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.1s;
  transform-origin: 75% 75%;
}

.right {
  transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
}

/* ---properties which tailwind lacks--- */
.bottom-double {
  border-bottom-style: double;
}
